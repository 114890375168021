/* React/Utils */
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import { removeNullValuesFromFromArray } from '../utils/reactUtils';

/* Material-UI */
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

/* Local */
import { ProductDetailsContext } from '../store/contexts/productDetailsContext';
import { ConsumerContext } from '../store/contexts/consumerContext';
import SearchContext from '../store/contexts/searchContext';
import { actions as consumerActions } from '../store/actions/consumerActions';
import { actions as searchActions } from '../store/actions/searchActions';
import useSnacks from './useSnack';
import translations from './hooks.i18n';
import { useGetUrlParams } from './useGetUrlParams';
import useSearchQuery from './useSearchQuery';
import { AthleteContext } from '../store/contexts/athleteContext';
import DotComCartContext from '../store/contexts/dotComCartContext';
import dotComCartActions from '../store/actions/dotComCartActions';

/* GraphQL */
import UPDATE_CART from '../graphql/mutations/updateCart.mutation';
import CREATE_CART from '../graphql/mutations/createCart.mutation';
import GET_CART from '../graphql/queries/getCart.query';

/**
 * A custom hook to streamline the use of the cart across PDP pages.
 * Import and use the function as regular hook, and deconstruct necessary actions:
 * const { setSnack, setError } = useCart({});
 */
export default function useCart({ followUpDotComCartUpdateComplete }) {
  /* Hooks */
  const { i18nString } = useContext(NikeI18nContext);
  const [consumerState, consumerDispatch] = useContext(ConsumerContext);
  const [productDetailsState] = useContext(ProductDetailsContext);
  const { setSnack, setError } = useSnacks();
  const [searchState, searchDispatch] = useContext(SearchContext);
  const [atheleteInfo] = useContext(AthleteContext);
  const history = useHistory();
  const useQuery = () => new URLSearchParams(history.location.search);
  const [, dotComCartDispatch] = useContext(DotComCartContext);

  /* Variables */
  const {
    productInfo,
    selectedSku,
    quantity,
    giftAmount,
    giftCardRecipientDetails,
  } = productDetailsState;
  const { newCart, createCart, updateCart } = consumerActions;
  const { setCountry, setDisplayModal } = searchActions;
  const { groups } = atheleteInfo;
  const { generatePath } = useSearchQuery();
  let query = useQuery();
  const marketplace = useGetUrlParams('mp');
  const { updateDotComCart, setDotComModalError, setStagedItems } = dotComCartActions;

  const {
    ADD_CART_ERROR,
    ADD_CART_ERROR_MISSING_AD_GROUP,
    GET_CART_ERROR,
    ADD_TO_CART,
    CART_ID_MISSING_ERROR,
    CASE_ID_MISSING_ERROR,
    ERROR_ADDING_TO_OCOBO_CART,
    SUCCESS_ADDING_TO_OCOBO_CART,
  } = mapValues(translations, i18nString);
  const addCartMissingADGroup = groups?.includes('App.DigitalCommerce.Buy.Checkout');

  /* Internal Functions */
  const mutateCartError = (err) =>
    addCartMissingADGroup
      ? setError(`${ADD_CART_ERROR} ${err.message}`)
      : setError(`${ADD_CART_ERROR_MISSING_AD_GROUP} ${err.message}`);

  const findCartId = () => {
    const cartInUrl = query.get('cartId');

    if (!cartInUrl || cartInUrl === 'null') {
      setError(`${CART_ID_MISSING_ERROR}`);
    }
    return cartInUrl;
  };

  // gets the case id from the url
  const getCaseId = () => {
    const caseIdInUrl = query.get('caseId');
    if (!caseIdInUrl || caseIdInUrl === 'null') {
      setError(`${CASE_ID_MISSING_ERROR}`);
    }
    return caseIdInUrl;
  };

  /* GraphQL Calls */
  const [createCartCall, { loading: createLoading }] = useMutation(CREATE_CART, {
    onError: mutateCartError,
    onCompleted: ({ createCart: data }) => {
      const { id, items } = data;
      // normalize incoming items to maintain them as appropriate inputs
      data.items = removeNullValuesFromFromArray(items);
      consumerDispatch(createCart(id, data));
    },
  });

  const [updateCartCall, { loading: updateLoading }] = useMutation(UPDATE_CART, {
    fetchPolicy: 'no-cache',
    onError: mutateCartError,
    onCompleted: ({ updateCart: data }) => {
      const { items } = data;

      // normalize incoming items to maintain them as appropriate inputs
      data.items = removeNullValuesFromFromArray(items);
      consumerDispatch(updateCart(data));
    },
  });

  const [getCartCall] = useLazyQuery(GET_CART, {
    onError: (err) => {
      // show error message only when cart id is valid
      if (!err?.message?.includes('404')) {
        setError(`${GET_CART_ERROR} ${err.message}`);
      }
    },
    onCompleted: ({ getCart: data }) => {
      consumerDispatch(updateCart(data));
      // check fetched cart against current mp in url, and create new cart if they don't match
      if (data.country === marketplace || !marketplace) {
        if (!searchState.selectedCountry) {
          searchDispatch(setCountry(data.country));
        }
        history.push(generatePath({ mp: data.country }));
        return;
      }
      searchDispatch(setDisplayModal('cart', data.country));
    },
  });

  /**
   * Functions for use in .com cart modal, same calls but with different success/error handling
   *
   * for using when creating an ocobo cart in .com modal */
  const [createCartCallDotComModal] = useMutation(CREATE_CART, {
    onError: (err) => dotComCartDispatch(setDotComModalError(err?.message)),
    onCompleted: ({ createCart: data }) => {
      const { id, items } = data;
      // normalize incoming items to maintain them as appropriate inputs
      data.items = removeNullValuesFromFromArray(items);
      consumerDispatch(createCart(id, data));
      setSnack(SUCCESS_ADDING_TO_OCOBO_CART);
    },
  });

  /* for using when updating ocobo cart in .com modal */
  const [updateCartCallDotComModal] = useMutation(UPDATE_CART, {
    fetchPolicy: 'no-cache',
    onError: (err) => {
      return dotComCartDispatch(setDotComModalError(err?.message));
    },
    onCompleted: ({ updateCart: data }) => {
      const { items } = data;

      // normalize incoming items to maintain them as appropriate inputs
      data.items = removeNullValuesFromFromArray(items);
      consumerDispatch(updateCart(data));
      setSnack(SUCCESS_ADDING_TO_OCOBO_CART);
    },
  });

  /* for using when updating .com cart */
  const [updateDotComCartCall, { loading: updateDotComCartLoading }] = useMutation(UPDATE_CART, {
    fetchPolicy: 'no-cache',
    onError: () => {
      dotComCartDispatch(setDotComModalError(ERROR_ADDING_TO_OCOBO_CART));
    },
    onCompleted: ({ updateCart: data }) => {
      const cartItemIds = data.items.map((item) => {
        return item.id;
      });
      dotComCartDispatch(updateDotComCart(cartItemIds));
      /*
        Clearing staged items now (e.g. setStagedItems([])) now that said items have been 
        successfully added to OMOBO Shop cart and removed from DotCom cart.
      */
      dotComCartDispatch(setStagedItems([]));
      if (followUpDotComCartUpdateComplete) followUpDotComCartUpdateComplete();
    },
  });

  const cartCurrency = searchState.cartCurrency;

  const isCartLoading = createLoading || updateLoading;

  /* Exported Functions */

  /**
   * Function to add an inline item to cart.  Pass it the return of one of the build
   * item functions, which will format the item properly.
   *
   * @param {Object} item – the items being added to cart, use a provided build function
   */
  const addToCart = (item) => {
    if (consumerState.cart?.id) {
      // if adding to existing cart, just call update with new item
      updateCartCall({
        variables: {
          id: consumerState?.cart?.id,
          input: [
            {
              op: 'add',
              path: '/items',
              value: item,
            },
          ],
        },
      });
    } else {
      const cartId = findCartId();
      createCartCall({
        variables: {
          id: cartId,
          input: {
            id: cartId,
            country: marketplace || 'US',
            brand: 'NIKE',
            /*
              Temp fix for UAT.
              Cart id in the channel needs to be removed one we forge & gagarin figures out
              how to detach the cart id from the user-brand-channel combo
              */
            channel: `NIKECOM_${cartId}`,
            /* Inline items almost always have merchPrice, but gift cards don't,
            so their currency is based on the marketplace. USD is left as last case fail state. */
            currency: productInfo?.merchPrice?.currency || cartCurrency || 'USD',
            items: [item],
          },
        },
      });
    }

    return createLoading || updateLoading;
  };

  /**
   * Function to fetch cart by id
   *
   * @param {UUID} id – existing card id to use to fetch cart
   */
  const fetchCart = (id) => {
    getCartCall({
      variables: {
        id,
      },
    });
  };

  /**
   * Function to build an inline cart item to match input schema.  Can also be used with VAS if
   * you pass true for isVAS.
   *
   * @param {String} pdpURI – the exact product path
   * @param {Bool} isVAS - optional boolean to include of VAS (for NBY or inline + VAS)
   * @param {String} offerId - optional string to include if item being added is EXCLUSIVE.
   */
  const buildCartItem = (pdpURI, isVAS = false, offerId = '') => {
    const cartId = findCartId();

    const item = {
      id: uuidv4(),
      quantity: quantity,
      skuId: selectedSku.id,
      itemData: { pdpURI: `${pdpURI}?caseId=${getCaseId()}&mp=${marketplace}&cartId=${cartId}` },
    };

    if (offerId) {
      item.offer = offerId;
    }

    if (isVAS) {
      item['valueAddedServices'] =
        productInfo?.consumerDesignInfo?.merchProduct?.valueAddedServices;
    }

    return item;
  };

  /**
   * Function to build an inline cart item to match input schema
   *
   * @param {String} pdpURI – the exact product path
   * @param {Array} vAS - array of value added services
   */
  const buildNBYCartItem = (pdpURI) => {
    const cartId = findCartId();

    return {
      id: uuidv4(),
      quantity: 1,
      skuId: productInfo?.consumerDesignInfo?.skuId,
      itemData: { pdpURI: `${pdpURI}?caseId=${getCaseId()}&mp=${marketplace}&cartId=${cartId}` },
      valueAddedServices: {
        id: productInfo?.consumerDesignInfo?.merchProduct?.valueAddedServices[0]?.id,
        instruction: {
          id: productInfo?.consumerDesignInfo?.shortId,
          type: 'customization/nike_id',
        },
      },
    };
  };

  /**
   * Function to build an inline cart item to match input schema
   *
   * @param {String} pdpURI – the exact product path
   * @param {Array} vAS - array of value added services
   */
  const buildGCItem = (pdpURI, isDigital = false) => {
    const cartId = findCartId();
    const valueAddedServices = productInfo?.validatedVAS;

    const item = {
      id: uuidv4(),
      quantity: quantity,
      giftCard: { amount: giftAmount },
      skuId: productInfo?.skuIds?.id,
      itemData: { pdpURI: `${pdpURI}?caseId=${getCaseId()}&mp=${marketplace}&cartId=${cartId}` },
    };

    if (isDigital) {
      const { firstName, lastName, email } = giftCardRecipientDetails;
      item.recipient = { firstName: firstName, lastName: lastName };
      item.shippingAddress = { country: marketplace ?? 'US', email: email };
    }

    if (valueAddedServices) {
      item['valueAddedServices'] = valueAddedServices;
    }

    return item;
  };

  /**
   * Hook function to reset cart.
   */
  const emptyCart = () => {
    consumerDispatch(newCart());
  };

  /**
   * Hook function to clear the current cart (unregistered).
   * Will return the new cartId (Note: not stored in Context until api call)
   *
   * @returns {string} - new cart UUID
   */
  const createEmptyCart = () => {
    consumerDispatch(newCart());

    return uuidv4();
  };

  /**
   * Abstracted Add to Cart Button for each PDP page, allows centralized loading.
   *
   * @param {fn} handleClick - handles clicking Add to Cart
   * @param {bool} [disabledBool=false] - boolean passed from component to influence disabling
   * @param {String} [styleName] - style name passed from component
   * @param {bool} [loading=false] - boolean indicating when the loading spinner should be shown.
   * @param {string} label - button text override
   *  This will be used when there are operations that need to be finished before the text can be
   * shown which also indicates this is ready.
   */
  const AddToCartButton = ({
    handleClick,
    disabledBool = false,
    loading = false,
    styleName,
    label,
  }) => {
    return (
      <Button
        variant='contained'
        color='primary'
        data-testid='add-to-cart-submit'
        disabled={disabledBool || isCartLoading}
        onClick={handleClick}
        className={styleName}>
        {loading ? <CircularProgress size='1.25rem' /> : label || ADD_TO_CART}
      </Button>
    );
  };

  return {
    addToCart,
    fetchCart,
    buildCartItem,
    buildGCItem,
    buildNBYCartItem,
    emptyCart,
    AddToCartButton,
    createEmptyCart,
    createCartCall,
    updateCartCall,
    updateDotComCartCall,
    updateDotComCartLoading,
    updateCartCallDotComModal,
    createCartCallDotComModal,
  };
}
