import { useContext } from 'react';
import { PermissionContext } from '../store/contexts/permissionContext';

/**
 * A custom hook to get user permission.
 * Import and use the function as regular hook, and deconstruct necessary actions:
 * const {hasPermission, hasAnyOfPermissions, hasAllOfPermissions } = useHasPermission();
 */
export default function usePermissions() {
  const [userPermissions] = useContext(PermissionContext);
  /**
   * A function which checks a parameter for being both a string, and non-empty.
   *
   * @param {any} param – the item to check the non-empty stringiness of
   */
  const isNonemptyString = (param) => typeof param === 'string' && param.length;

  /**
   * Checks if the user has passed in permission
   * @param {string} permission - a permissions to check that a user has
   * Note: permission false if permission is empty
   */
  const hasPermission = (permission) => {
    // if we have a "permission" prop
    if (isNonemptyString(permission)) {
      return userPermissions.includes(permission.toLowerCase());
    }
    return false;
  };

  /**
   * Checks if the user has any of the passed in permission
   * @param {array} anyOf - a list of permissions to check that a user has any of
   * Note: Returns false if anyOf is not an array
   */
  const hasAnyOfPermissions = (anyOf) => {
    const anyOfPermissions = [];

    // if we have an "anyOf" prop
    if (Array.isArray(anyOf)) {
      // filter out anything that's not a populated string, and lower-case it
      const filteredPermissions = anyOf
        .filter(isNonemptyString)
        .map((permission) => permission.toLowerCase());
      // and add it to our list of "Any" permissions
      filteredPermissions.length && anyOfPermissions.push(...filteredPermissions);
    } else {
      return false;
    }

    // check that the user has one or more of the "Any" permissions (or that there are none)
    const hasAnyOfPermissions = anyOfPermissions.some((permission) =>
      userPermissions.includes(permission)
    );
    return hasAnyOfPermissions;
  };

  /**
   * Checks if the user has all of the passed in permission
   * @param {*} allOf - a list of permissions to check that a user has all of
   * Note: Returns false if allOf is not an array
   */
  const hasAllOfPermissions = (allOf) => {
    const allOfPermissions = [];

    // if we have an "allOf" prop
    if (Array.isArray(allOf)) {
      // filter out anything that's not a populated string, and lower-case it
      const filteredPermissions = allOf
        .filter(isNonemptyString)
        .map((permission) => permission.toLowerCase());
      // and add it to our list of "All" permissions
      filteredPermissions.length && allOfPermissions.push(...filteredPermissions);
    } else {
      return false;
    }

    // check that the user has all of the "All" permissions (or that there are none)
    const hasAllOfPermissions = allOfPermissions.every((permission) =>
      userPermissions.includes(permission)
    );
    return hasAllOfPermissions;
  };

  return { hasPermission, hasAnyOfPermissions, hasAllOfPermissions };
}
